















import { PageViewInterface } from '@/types';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class PageView extends Vue {
	default: PageViewInterface = {
		accountId: 0,
		pageName: '',
		pageSection: '',
		path: this.$route.path,
		updateFunc: this.doNothing,
	};

	private lockUpdate = false;
	private async doNothing(): Promise<void> {
		if (this.lockUpdate || this.$pageView.updateFunc == this.doNothing) return;
		this.lockUpdate = true;
		await this.$pageView.updateFunc();
		this.lockUpdate = false;
	}

	created(): void {
		const names = (this.$route.name as string).split(':');
		Vue.prototype.$pageView = this.default;
		this.$pageView.pageName = names[0];
		this.$pageView.pageSection = names[1];

		this.$router.afterEach(() => {
			const names = (this.$route.name as string).split(':');
			this.$pageView.accountId = 0;
			this.$pageView.pageName = names[0];
			this.$pageView.pageSection = names[1];
			this.$pageView.path = this.$route.path;
			this.$pageView.updateFunc = this.doNothing;
		});

		setInterval(this.$pageView.updateFunc, 3000);
	}
}
