









import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class HelpDeskMessageBlock extends Vue {
	@Prop() dbOperId!: number;
	@Prop() created!: string;
}
