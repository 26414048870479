








import { Vue, Component } from 'vue-property-decorator';

interface ShiftsInterface {
	Id: number;
	DbOperId: number;
	Created: string;
	Closed: string;
}

@Component
export default class HelpDescShifts extends Vue {
	loading = true;
	items: ShiftsInterface[] = [];
	headers = [
		{ text: 'Оператор', value: 'DbOperId' },
		{ text: 'Начало смены', value: 'Created' },
		{ text: 'Конец смены', value: 'Closed' },
	];

	mounted(): void {
		this.$api.request<ShiftsInterface[]>('HelpDesk/GetShifts').then((r) => {
			this.loading = false;
			if (r == null) return;
			this.items = r.data;
		});
	}
}
