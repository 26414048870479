

































import { Vue, Component } from 'vue-property-decorator';
import { ComboBoxItemInterface, HelpDeskUserInterface } from '@/types';
import { DataTableHeader } from 'vuetify';

@Component
export default class HelpDeskUsers extends Vue {
	settingsDialog = false;
	search = '';
	loading = false;
	selectedItem = {} as HelpDeskUserInterface;
	items: HelpDeskUserInterface[] = [];
	headers: DataTableHeader[] = [
		{ text: 'ID', value: 'ChatId' },
		{ text: 'Имя', value: 'Name' },
		{ text: 'Л/С', value: 'AccountId' },
		{ text: 'Время регистрации', value: 'Created' },
		{ text: 'Оператор', value: 'DbOperId' },
		{ text: 'Блокировка', value: 'Blocked', align: 'end' },
	];

	opers: ComboBoxItemInterface[] = [];
	selectedOper = 0;
	selectedAccountId: number | null = null;

	setSettings(): void {
		this.loading = true;
		this.selectedItem.DbOperId = this.selectedOper;
		this.selectedItem.AccountId = Number(this.selectedAccountId) == 0 ? null : this.selectedAccountId;

		this.$api.request('HelpDesk/UpdateUser', this.selectedItem).then(() => {
			this.settingsDialog = false;
			this.loading = false;
		});
	}

	showSettings(item: HelpDeskUserInterface): void {
		this.settingsDialog = true;
		this.selectedItem = item;
		this.selectedOper = item.DbOperId;
		this.selectedAccountId = item.AccountId;
	}

	setBlocked(item: HelpDeskUserInterface): void {
		this.$api.request<HelpDeskUserInterface>('HelpDesk/SetBlocked', item).then((r) => {
			if (r == null) return;
			const newItem = r.data;
			item.Blocked = newItem.Blocked;
		});
	}

	mounted(): void {
		this.loading = true;
		this.$api.request('HelpDesk/GetAllUsers').then((r) => {
			this.loading = false;
			if (r == null) return;

			this.items = r.data as HelpDeskUserInterface[];
		});

		this.$api.request<ComboBoxItemInterface[]>('Account/GetActiveDbOpers').then((r) => {
			if (r == null) return;
			this.opers = r.data;
			this.opers.push({ Id: 0, Name: 'не задан' });
		});
	}
}
