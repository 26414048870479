



















































import { Vue, Component } from 'vue-property-decorator';
import { AccountInterface, ComboBoxItemInterface } from '@/types';

@Component
export default class AccountInfo extends Vue {
	loading = true;
	account = {} as AccountInterface;
	phones: ComboBoxItemInterface = {} as ComboBoxItemInterface;
	address = '';
	provider = '';

	mounted(): void {
		this.$pageView.accountId = Number(this.$route.params.id);

		this.$api.request<AccountInterface>('Account/GetAccount', this.$pageView.accountId).then((r) => {
			this.loading = false;
			if (r == null) return;
			this.account = r.data;

			this.$api.request<string>('Account/GetOName', r.data.Provider).then((r) => {
				if (r == null) return;
				this.provider = r.data;
			});
		});
		this.$api.request<string>('Account/GetAddress', this.$pageView.accountId).then((r) => {
			if (r == null) return;
			this.address = r.data;
		});
		this.$api.request('Account/GetPhones', this.$pageView.accountId).then((r) => {
			if (r == null) return;
			this.phones = r.data as ComboBoxItemInterface;
		});
	}
}
