import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import Home from '@/Views/Home.vue';

import AccountSearch from '@/Views/Account/Search.vue';
import AccountAction from '@/Views/Account/Action.vue';
import AccountInfo from '@/Views/Account/Info.vue';
import AccountSMS from '@/Views/Account/SMS.vue';
import AccountCreateService from '@/Views/Account/CreateService.vue';
import AccountDomofons from '@/Views/Account/Domofons.vue';
import AccountCreate from '@/Views/Account/Create.vue';

import HelpDeskUsers from '@/Views/HelpDesk/Users.vue';
import HelpDeskTickets from '@/Views/HelpDesk/Tickets.vue';
import HelpDeskTicket from '@/Views/HelpDesk/Ticket.vue';
import HelpDeskShifts from '@/Views/HelpDesk/Shifts.vue';

import DirectoryDomofons from '@/Views/Directory/Domofons.vue';

import Test from '@/Views/Test.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
	{ path: '/', name: 'История изменений', component: Home },

	//Account
	{ path: '/account/search', name: 'Поиск лицевого счета', component: AccountSearch },
	{ path: '/account/action/:id', name: 'Действия с лицевым счетом', component: AccountAction },
	{ path: '/account/info/:id', name: 'Карточка лицевого счета', component: AccountInfo },
	{ path: '/account/sms/:id', name: 'Отправка SMS', component: AccountSMS },
	{ path: '/account/create-service/:id', name: 'Добавить услугу', component: AccountCreateService },
	{ path: '/account/domofons/:id', name: 'Управление домофонами', component: AccountDomofons },
	{ path: '/account/create', name: 'Создать лицевой счет', component: AccountCreate },

	//HelpDesk
	{ path: '/helpdesk/users', name: 'Пользователи:HelpDesk', component: HelpDeskUsers },
	{ path: '/helpdesk/tickets', name: 'Обращения:HelpDesk', component: HelpDeskTickets },
	{ path: '/helpdesk/ticket/:id', name: 'Обращение №:HelpDesk', component: HelpDeskTicket },
	{ path: '/helpdesk/shifts', name: 'Смены:HelpDesk', component: HelpDeskShifts },

	{ path: '/helpdesk/actions', name: ':TEST', component: Test },

	//Directory
	{ path: '/directory/domofons', name: 'Домофоны:Справочники', component: DirectoryDomofons },

	{ path: '*', name: '404 Страница не найдена', component: Home },
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
