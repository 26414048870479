




import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class AccountLink extends Vue {
	@Prop()
	id!: number;
}
