import Vue from 'vue';

export class Colors {
	public readonly button = 'orange darken-4 white--text';

	public readonly alertError = 'red darken-4';
	public readonly alertWarning = 'orange darken-2';
	public readonly alertInfo = 'blue darken-4';
}

declare module 'vue/types/vue' {
	interface Vue {
		$colors: Colors;
	}
}

Vue.prototype.$colors = new Colors();
