












































import { Vue, Component } from 'vue-property-decorator';
import PageView from '@/Components/PageView.vue';
import Navigation from '@/Components/Navigation.vue';
import { AlertType, VueFormWrapper } from '@/types';

@Component({
	components: { PageView, Navigation },
})
export default class App extends Vue {
	userName = '';
	password = '';
	AlertType = AlertType;

	loading = false;
	isValid = false;
	errorText = '';

	login(): void {
		this.errorText = '';
		(this.$refs.form as VueFormWrapper).validate();
		if (!this.isValid) return;
		this.loading = true;

		this.loading = true;
		this.$api
			.request<string>('Account/Login', { Login: this.userName, Password: this.password })
			.then((r) => {
				this.loading = false;
				if (r == null) return;
				if (r.data == '') {
					this.errorText = 'Ошибка авторизации';
				} else {
					this.$api.setToken(r.data);
				}
			});
	}

	getAlertClass(): string {
		switch (this.$api.alert.type) {
			case AlertType.error:
				return this.$colors.alertError;
			case AlertType.info:
				return this.$colors.alertInfo;
			case AlertType.warning:
				return this.$colors.alertWarning;
		}
	}
}
