var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-2",attrs:{"loading":_vm.loading,"items":_vm.items,"headers":_vm.headers,"item-key":"Id","sort-desc":"","sort-by":"Created"},scopedSlots:_vm._u([{key:"header.Actions",fn:function(){return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","title":"Фильтрация"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("filter_alt")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.lastTicketId = 0;
							_vm.filterOptions.State = _vm.filterOptions.State == _vm.HelpDeskTicketStateTypes.Closed ? undefined : _vm.HelpDeskTicketStateTypes.Closed;}}},[_c('v-list-item-icon',[(_vm.filterOptions.State == _vm.HelpDeskTicketStateTypes.Closed)?_c('v-icon',[_vm._v("done")]):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Показать закрытые")])],1)],1),_c('v-list-item',{on:{"click":function($event){_vm.lastTicketId = 0;
							_vm.filterOptions.Owner = !_vm.filterOptions.Owner;}}},[_c('v-list-item-icon',[(_vm.filterOptions.Owner)?_c('v-icon',[_vm._v("done")]):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Показать мои")])],1)],1)],1)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
							var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","title":"Действия"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.newIntendantDialog = true}}},[_c('v-list-item-title',[_vm._v("Передать мои заявки")])],1)],1)],1)]},proxy:true},{key:"item.Intendant",fn:function(ref){
							var item = ref.item;
return [(item.Intendant)?[_c('db-oper-name',{attrs:{"id":item.Intendant}})]:_vm._e()]}},{key:"item.State",fn:function(ref){
							var item = ref.item;
return [_c('state-name',{attrs:{"state":item.State}})]}},{key:"item.Actions",fn:function(ref){
							var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"blue","to":'/helpdesk/ticket/' + item.Id}},[_vm._v("К обращению")])]}},{key:"expanded-item",fn:function(ref){
							var headers = ref.headers;
							var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v("More info about "+_vm._s(item.Id))])]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.newIntendantDialog),callback:function ($$v) {_vm.newIntendantDialog=$$v},expression:"newIntendantDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Назначить нового ответственного")]),_c('v-card-text',[_c('v-autocomplete',{attrs:{"disabled":_vm.loading,"items":_vm.opers,"color":"blue-grey lighten-2","label":"Ответственный","item-text":"Name","item-value":"Id"},model:{value:(_vm.selectedOper),callback:function ($$v) {_vm.selectedOper=$$v},expression:"selectedOper"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"blue","disabled":_vm.selectedOper <= 0 || _vm.selectedOper == _vm.$api.currentOperId},on:{"click":function($event){return _vm.setIntendant()}}},[_vm._v("Применить")]),_c('v-btn',{attrs:{"text":"","color":"blue"},on:{"click":function($event){_vm.newIntendantDialog = false}}},[_vm._v("Отмена")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }