













import { Vue, Component } from 'vue-property-decorator';

interface DomofonCode {
	Id: number;
	Created: Date;
	Code: string;
	Domofon: string;
}

@Component
export default class AccountDomofons extends Vue {
	loading = false;
	items: DomofonCode[] = [];
	headers = [
		{ text: 'Домофон', value: 'Domofon' },
		{ text: 'Код домофона', value: 'Code' },
		{ text: 'Created', value: 'Created' },
	];

	mounted(): void {
		this.$pageView.accountId = Number(this.$route.params.id);

		this.loading = true;
		this.$api.request<DomofonCode[]>('Account/GetDomofonCodes', this.$pageView.accountId).then((r) => {
			this.loading = false;
			if (r == null) return;

			this.items = r.data;
		});
	}
}
