






























import { Vue, Component } from 'vue-property-decorator';

interface MenuItem {
	name: string;
	visible: boolean;
	icon: string;
	link: string;
}

interface ExPanel {
	name: string;
	visible: boolean;
	items: MenuItem[];
}

@Component
export default class Navigation extends Vue {
	NavPanel: ExPanel[] = [
		{
			name: 'HelpDesk',
			visible: true,
			items: [
				{ icon: 'supervisor_account', name: 'Пользователи', visible: true, link: '/helpdesk/users' },
				{ icon: 'confirmation_number', name: 'Обращения', visible: true, link: '/helpdesk/tickets' },
				{ icon: 'alarm', name: 'Смены', visible: true, link: '/helpdesk/shifts' },
				{ icon: 'support', name: 'Функции', visible: true, link: '/helpdesk/actions' },
			],
		},
		{
			name: 'Справочники',
			visible: true,
			items: [{ icon: 'meeting_room', name: 'Домофоны', visible: true, link: '/directory/domofons' }],
		},
		{
			name: 'Лицевой счет',
			visible: true,
			items: [
				{ icon: 'search', name: 'Поиск', visible: true, link: '/account/search' },
				{ icon: 'person_add', name: 'Создать л/сч', visible: true, link: '/account/create' },
			],
		},
	];

	panel = 0;

	updateActivePanel(index: number): void {
		if (this.panel === undefined || this.panel !== index) {
			localStorage.setItem('NavigationActivePanel', index.toString());
		} else {
			localStorage.removeItem('NavigationActivePanel');
		}
	}

	mounted(): void {
		const nPanel = localStorage.getItem('NavigationActivePanel');
		if (nPanel) {
			this.panel = parseInt(nPanel);
		}
	}
}
