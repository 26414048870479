





































































































import { Vue, Component } from 'vue-property-decorator';
import { HelpDeskTicketMessageInterface, HelpDeskTicketMessageType, ComboBoxItemInterface, HelpDeskTicketInterface, HelpDeskUserInterface, HelpDeskPresetInterface, AlertType } from '@/types';
import HelpDeskMessage from '@/Components/HelpDeskMessage.vue';
import pako from 'pako';

@Component({ components: { HelpDeskMessage } })
export default class Ticket extends Vue {
	item = { State: 0 } as HelpDeskTicketInterface;

	user = {} as HelpDeskUserInterface;

	answer = '';
	settingsDialog = false;

	uploadDialog = false;
	fileType?: HelpDeskTicketMessageType;
	uploadFile: File | null = null;

	lastTicketMessageId = 0;

	opers: ComboBoxItemInterface[] = [];
	selectedOper = 0;

	loading = true;
	items: HelpDeskTicketMessageInterface[] = [];

	get presets(): HelpDeskPresetInterface[] {
		return [
			{ Id: 0, Name: 'Свой текст' },
			{
				Id: 4,
				Name: 'Мы подготавливаем ответ...',
				Description: 'Используйте этот пресет, если ответ абоненту занимает длительное время.',
				Text: 'Мы подготавливаем ответ по Вашему обращению и напишем Вам как только будет информация.',
			},
			{
				Id: 1,
				Name: 'Предложить закрыть обращение',
				Description: 'Используйте этот пресет если считате, что абонент получил полноценный ответ и у него не осталось вопросов. Пожалуйста, не отправляйте пресет несколько раз подряд.',
				Text: 'Остались ли у Вас еще какие-нибудь вопросы?',
				Buttons: ['CallbackCloseTicket:' + this.item.Id, 'CallbackDontCloseTicket'],
			},
			{
				Id: 2,
				Name: 'Узнать лицевой счет',
				Description:
					'Пользователь получит сообщение от бота, в котором его попросят ввести лицевой счет. Этот лицевой счет автоматически привяжется к аккаунту телеграм. Его можно будет увидеть в статусе обращения. Если к аккаунту уже привязан лицевой счет, то пользователь получит сообщение, в котором он узнает какой лицевой счет привязан к телеграм.',
				Action: 'SetAccount',
			},
			{ Id: 3, Name: 'Отправить всю переписку', Description: 'Пользователь получит всю текстовую переписку по этому обращению. Переписка не будет включать в себя документы и изображения.', Action: 'SendHistory' },
			{
				Id: 5,
				Name: 'Закрыть обращение принудительно',
				Description: 'Отправляет уведомление пользователю и принудительно закрывает обращение если с момента последнего сообщения прошло более суток.',
				Text: 'Мы долго не получали от Вас ответ и закрыли это обращение. Если вопрос остался не решенным, Вы всегда можете открыть новое обращение с отсылкой к этому обращению.',
			},
			{
				Id: 6,
				Name: 'Узнать контактный телефон',
				Description: 'Используйте этот пресет, что бы узнать контактный номер телефона.',
				Text: 'Пожалуйста, сообщите Ваш контактный номер телефона, по которому мы сможем с Вами связаться.',
			},
		];
	}
	preset = this.presets[0];
	presetAnswer = '';

	get isBusy(): boolean {
		return (!this.answer && this.preset.Id == 0) || this.loading || this.item.State == 0;
	}

	exportToSD(): void {
		this.loading = true;
		this.$api.request('HelpDesk/CreateServiceDescTicket', this.item.Id).then((r) => {
			this.loading = false;
			if (r == null) return;

			this.$api.alertShow(AlertType.info, 'Запрос на создание заявки в ServiceDesk был успешно обработан. Проверьте ServiceDesk.');
		});
	}

	setTicketIntendantToMe(): void {
		const form = {
			TicketId: this.item.Id,
			Type: HelpDeskTicketMessageType.SetIntendant,
			Intendant: this.$api.currentOperId,
		} as HelpDeskTicketMessageInterface;

		this.$api.request<HelpDeskTicketMessageInterface>('HelpDesk/SendAnswer', form).then(() => {
			this.settingsDialog = false;
			this.loading = false;
		});
	}

	setSettings(): void {
		const form = { TicketId: this.item.Id } as HelpDeskTicketMessageInterface;

		if (this.item.Intendant != this.selectedOper) {
			this.item.Intendant = this.selectedOper;
			form.Intendant = this.selectedOper;
			form.Type = HelpDeskTicketMessageType.SetIntendant;
		}

		if (form.Type) {
			this.loading = true;

			this.$api.request<HelpDeskTicketMessageInterface>('HelpDesk/SendAnswer', form).then(() => {
				this.settingsDialog = false;
				this.loading = false;
			});
		}
	}

	showSettings(): void {
		this.settingsDialog = true;
		this.selectedOper = this.item.Intendant;
	}

	sendImage(): void {
		this.fileType = HelpDeskTicketMessageType.Photo;
		this.uploadDialog = true;
		this.uploadFile = null;
	}

	sendFile(): void {
		this.fileType = HelpDeskTicketMessageType.Document;
		this.uploadDialog = true;
		this.uploadFile = null;
	}

	async sendUpload(): Promise<void> {
		this.loading = true;

		const form = {
			TicketId: this.item.Id,
			Type: this.fileType,
		} as HelpDeskTicketMessageInterface;

		if (this.fileType == HelpDeskTicketMessageType.Photo) {
			const r = await this.$api.upload<string>('HelpDesk/UploadImage', this.uploadFile as File);
			if (r == null) return;
			form.Message = r.data;
		} else if (this.fileType == HelpDeskTicketMessageType.Document) {
			const r = await this.$api.upload<string>('HelpDesk/UploadDocument', this.uploadFile as File);
			if (r == null) return;
			form.Message = r.data;
		}

		const r = await this.$api.request<HelpDeskTicketMessageInterface>('HelpDesk/SendAnswer', form);
		this.loading = false;
		this.uploadDialog = false;

		if (r == null) return;
		this.answer = '';
		this.items.push(r.data);
	}

	sendAnswer(): void {
		this.loading = true;

		const form = { TicketId: this.item.Id } as HelpDeskTicketMessageInterface;

		if (this.preset.Id == 0) {
			form.Type = HelpDeskTicketMessageType.Text;
			form.Message = btoa(String.fromCharCode.apply(null, pako.gzip(this.answer) as number[]));
		} else {
			form.Type = HelpDeskTicketMessageType.Preset;
			form.Message = btoa(String.fromCharCode.apply(null, pako.gzip(JSON.stringify(this.preset)) as number[]));
		}

		this.$api.request<HelpDeskTicketMessageInterface>('HelpDesk/SendAnswer', form).then((r) => {
			this.loading = false;
			if (r == null) return;

			this.answer = '';
			this.items.push(r.data);

			this.update();
		});
	}

	async update(): Promise<void> {
		const r = await this.$api.request<number>('HelpDesk/GetLastTicketMessageId', this.item.Id);
		if (r == null || this.lastTicketMessageId >= r.data) return;
		this.lastTicketMessageId = r.data;

		const r2 = await this.$api.request<HelpDeskTicketMessageInterface[]>('HelpDesk/GetTicketMessages', this.item.Id);
		if (r2 == null) return;
		this.items = r2.data;

		const r3 = await this.$api.request<HelpDeskTicketInterface>('HelpDesk/GetTicket', this.item.Id);
		this.item.State = -1;
		if (r3 == null) return;
		this.item = r3.data;

		const r4 = await this.$api.request<HelpDeskUserInterface>('HelpDesk/GetUser', this.item.UserId);
		if (r4 == null) return;
		this.user = r4.data;
	}

	mounted(): void {
		this.item.Id = parseInt(this.$route.params.id);
		this.$pageView.pageName = `${this.$route.name}${this.item.Id}`;
		this.$pageView.updateFunc = this.update;

		this.loading = true;
		this.update().then(() => {
			this.loading = false;
		});

		this.$api.request('HelpDesk/GetIntendants').then((r) => {
			if (r == null) return;
			this.opers = r.data as ComboBoxItemInterface[];
		});
	}
}
