




import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class DbOperName extends Vue {
	@Prop()
	id!: number;

	private get operName() {
		if (this.$api.dbOpers[this.id]) {
			return this.$api.dbOpers[this.id].Name;
		}
		return '';
	}

	private get operClass() {
		if (this.$api.dbOpers[this.id]) {
			return this.$api.dbOpers[this.id].Active ? 'teal--text' : 'red--text';
		}
		return '';
	}
}
