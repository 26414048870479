







































































import { Vue, Component } from 'vue-property-decorator';
import { HelpDeskTicketStateTypes, ComboBoxItemInterface, HelpDeskTicketMessageInterface, HelpDeskTicketMessageType } from '@/types';
import { DataTableHeader } from 'node_modules/vuetify/types';

interface TicketInterface {
	Id: number;
	Intendant: number;
	State: HelpDeskTicketStateTypes;
	Created: string;
}

interface TicketFilterOptions {
	State?: HelpDeskTicketStateTypes;
	Owner: boolean;
}

@Component
export default class Tickets extends Vue {
	HelpDeskTicketStateTypes = HelpDeskTicketStateTypes;
	searchState = false;
	filterOptions: TicketFilterOptions = { Owner: false };
	newIntendantDialog = false;

	opers: ComboBoxItemInterface[] = [];
	selectedOper = -1;
	lastTicketId = -1;

	loading = true;
	items: TicketInterface[] = [];
	headers: DataTableHeader[] = [
		{ text: 'ID', value: 'Id' },
		{ text: 'Время регистрации', value: 'Created' },
		{ text: 'Интендант', value: 'Intendant' },
		{ text: 'Состояние', value: 'State' },
		{ text: '', value: 'Actions', sortable: false, align: 'end' },
	];

	setIntendant(): void {
		if (this.selectedOper > 0 && this.selectedOper != this.$api.currentOperId) {
			this.items.forEach((i) => {
				const form = { TicketId: i.Id } as HelpDeskTicketMessageInterface;

				if (i.Intendant == this.$api.currentOperId && i.State != HelpDeskTicketStateTypes.Closed) {
					i.Intendant = this.selectedOper;
					form.Intendant = this.selectedOper;
					form.Type = HelpDeskTicketMessageType.SetIntendant;

					this.loading = true;

					this.$api.request('HelpDesk/SendAnswer', form).then((r) => {
						this.loading = false;
						if (r == null) return;
					});
				}
			});
		}
		this.newIntendantDialog = false;
	}

	async update(): Promise<void> {
		const r = await this.$api.request<number>('HelpDesk/GetLastTicketId');
		if (r == null) return;

		if (this.lastTicketId < r.data) {
			this.lastTicketId = r.data;
			this.loading = true;

			const r2 = await this.$api.request<TicketInterface[]>('HelpDesk/GetTickets', this.filterOptions);
			this.loading = false;
			if (r2 == null) return;

			this.items = r2.data;
		}
	}

	mounted(): void {
		this.$api.request<ComboBoxItemInterface[]>('HelpDesk/GetIntendants').then((r) => {
			if (r == null) return;
			this.opers = r.data;
		});

		this.$pageView.updateFunc = this.update;
		this.update();
	}
}
