export enum AlertType {
	error,
	warning,
	info,
}

export interface DbOperInterface {
	Id: number;
	Name: string;
	Active: boolean;
}

export enum HelpDeskTicketMessageType {
	Text = 0,
	Preset = 1,
	SetState = 2,
	SetIntendant = 3,
	Error = 4,
	Sticker = 5,
	Photo = 6,
	Document = 7,
}

export interface HelpDeskTicketMessageInterface {
	Id: number;
	TicketId: number;
	Message: string;
	Type: HelpDeskTicketMessageType;
	Intendant: number;
	State: number;
	DbOperId: number;
	Created: string;
}

export interface HelpDeskTicketInterface {
	Id: number;
	UserId: number;
	State: number;
	Intendant: number;
	Created: string;
	Closed: string;
}

export interface HelpDeskUserInterface {
	Id: number;
	ChatId: number;
	Name: string;
	Type: number;
	AccountId: number | null;
	Blocked: number;
	Created: string;
	DbOperId: number;
}

export interface HelpDeskPresetInterface {
	Id: number;
	Name: string;
	Text?: string;
	Buttons?: string[];
	Action?: string;
	Description?: string;
}

export interface HelpDeskImageInterface {
	FileName: string;
	Text: string;
	Size: number;
	Width: number;
	Height: number;
}

export interface HelpDeskDocumentInterface {
	FileName: string;
	OriginalFileName: string;
	Size: number;
	MimeType: string;
	Text: string;
}

export enum HelpDeskTicketStateTypes {
	New = 0,
	Closed = 1,
	InWork = 2,
	AwaitAnswer = 3,
	Suspended = 4,
	Error = 5,
}

export interface PageViewInterface {
	accountId: number;
	path: string;
	pageName: string;
	pageSection?: string;
	updateFunc: UpdateDelegate;
}

export interface ComboBoxItemInterface {
	Id: number;
	Name: string;
	Value?: string | number;
}

export interface AccountInterface {
	Id: number;
	LName: string;
	FName: string;
	MName: string;
	Provider: string;
	Balance: number;
	Credit: number;
	Threshold: number;
	Created: string;
	DboperId: number;
}

export type VueFormWrapper = Vue & { validate: () => boolean };

export interface UpdateDelegate {
	(): Promise<void>;
}
