














import { Vue, Component } from 'vue-property-decorator';
import { ComboBoxItemInterface } from '@/types';
import { DataTableHeader } from 'node_modules/vuetify/types';

interface AccountSearchInterface {
	Id: number;
	Name: string;
	Balance: number;
	Phones: string;
	Tarifs: string;
}

@Component
export default class Search extends Vue {
	loading = false;
	search: string | number = '';

	accountSearchTypeItems: ComboBoxItemInterface[] = [
		{ Id: 0, Name: 'Лицевой счет', Value: 'Account/GetById' },
		{ Id: 1, Name: 'Идентификатор', Value: 'Account/GetByIdentifier' },
	];
	accountSearchType = this.accountSearchTypeItems[0];

	items: AccountSearchInterface[] = [];
	headers: DataTableHeader[] = [
		{ text: 'Лицевой счет', value: 'Id' },
		{ text: 'ФИО', value: 'Name' },
		{ text: 'Баланс', value: 'Balance' },
		{ text: 'Телефоны', value: 'Phones', sortable: false },
		{ text: 'Тарифы', value: 'Tarifs', sortable: false },
	];

	searchComplete(e: KeyboardEvent): void {
		if (e.key != 'Enter') return;

		this.loading = true;

		this.$api.request<AccountSearchInterface[]>(this.accountSearchType.Value as string, this.search).then((r) => {
			this.loading = false;
			if (r == null) return;
			this.items = r.data;
		});
	}
}
