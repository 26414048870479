var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.settingsDialog),callback:function ($$v) {_vm.settingsDialog=$$v},expression:"settingsDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Настройки пользователя")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Лицевой счет"},model:{value:(_vm.selectedAccountId),callback:function ($$v) {_vm.selectedAccountId=_vm._n($$v)},expression:"selectedAccountId"}}),_c('v-autocomplete',{attrs:{"disabled":_vm.loading,"items":_vm.opers,"color":"blue-grey lighten-2","label":"Оператор","item-text":"Name","item-value":"Id"},model:{value:(_vm.selectedOper),callback:function ($$v) {_vm.selectedOper=$$v},expression:"selectedOper"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"blue"},on:{"click":function($event){return _vm.setSettings()}}},[_vm._v("Применить")]),_c('v-btn',{attrs:{"text":"","color":"blue"},on:{"click":function($event){_vm.settingsDialog = false}}},[_vm._v("Отмена")])],1)],1)],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"search":_vm.search,"items":_vm.items,"headers":_vm.headers,"sort-desc":"","sort-by":"Created"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Поиск"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.Name",fn:function(ref){
var item = ref.item;
return [_c('user-name',{attrs:{"type":item.Type,"name":item.Name}})]}},{key:"item.AccountId",fn:function(ref){
var item = ref.item;
return [_c('account-link',{attrs:{"id":item.AccountId}})]}},{key:"item.DbOperId",fn:function(ref){
var item = ref.item;
return [(item.DbOperId)?_c('span',[_c('db-oper-name',{attrs:{"id":item.DbOperId}})],1):_vm._e()]}},{key:"item.Blocked",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-8",attrs:{"icon":""},on:{"click":function($event){return _vm.showSettings(item)}}},[_c('v-icon',[_vm._v("settings")])],1),_c('v-switch',{staticClass:"mt-0 float-right",attrs:{"hide-details":"","color":"red darken-4"},on:{"click":function($event){return _vm.setBlocked(item)}},model:{value:(item.Blocked),callback:function ($$v) {_vm.$set(item, "Blocked", $$v)},expression:"item.Blocked"}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }