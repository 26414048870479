
















import { Vue, Component } from 'vue-property-decorator';
import { AlertType, ComboBoxItemInterface } from '@/types';
import { DataTableHeader } from 'vuetify';

interface AccountService {
	Id: number;
	Service: string;
	Identifier: string;
	Tarif: string;
	DboperId: string;
	Created: string;
}

interface NewService {
	AccountId: number;
	TarifId: number;
}

@Component
export default class CreateService extends Vue {
	loading = false;

	//TODO сделай нормально
	serviceItems: ComboBoxItemInterface[] = [{ Id: 66, Name: 'Аренда' }];
	service = this.serviceItems[0];

	tarifItems: ComboBoxItemInterface[] = [
		{ Id: 269, Name: 'Аренда модуля' },
		{ Id: 270, Name: 'Аренда приставки' },
		{ Id: 274, Name: 'Аренда роутера 100Мбит' },
		{ Id: 275, Name: 'Аренда роутера 1000Мбит' },
		{ Id: 281, Name: 'Аренда роутера 1Гбит БО' },
		{ Id: 288, Name: 'Почтовый ящик' },
		{ Id: 289, Name: 'Телефон' },
	];
	tarif = this.tarifItems[0];

	items: AccountService[] = [];
	headers: DataTableHeader[] = [
		{ text: 'Услуга', value: 'Service' },
		{ text: 'Идентификатор', value: 'Identifier' },
		{ text: 'Тариф', value: 'Tarif' },
		{ text: 'Оператор', value: 'DboperId' },
		{ text: 'Время создания', value: 'Created' },
	];

	createService(): void {
		var n: NewService = {
			AccountId: this.$pageView.accountId,
			TarifId: this.tarif.Id,
		};

		this.loading = true;
		this.$api.request<boolean>('Account/CreateAccountService', n).then((r) => {
			this.loading = false;
			if (r == null) return;
			this.update();
			this.$api.alertShow(AlertType.info, 'Услуга успешно создана');
		});
	}

	update(): void {
		this.loading = true;
		this.$api.request<AccountService[]>('Account/GetAccountServices', this.$pageView.accountId).then((r) => {
			this.loading = false;
			if (r == null) return;
			this.items = r.data;
		});
	}

	mounted(): void {
		this.$pageView.accountId = Number(this.$route.params.id);
		this.update();
	}
}
