
















import { AlertType, ComboBoxItemInterface } from '@/types';
import { Vue, Component } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

interface SMSInterface {
	Id: number;
	AccountId: number;
	MobileNumber: string;
	Result: string;
	DbOperId: number;
	Created: string;
}

interface SendSMSInterface {
	AccountId: number;
	IdentId: number;
	PhoneId: number;
	Preset: number;
}

@Component
export default class SMS extends Vue {
	loading = true;
	smsCategoryItems: ComboBoxItemInterface[] = [
		{ Id: -1, Name: '' },
		{ Id: 0, Name: 'Пароль лицевого счета' },
		{ Id: 1, Name: 'Пароль на вход в интернет' },
		{ Id: 2, Name: 'Ссылка на Telegram' },
	];
	smsCategory = this.smsCategoryItems[0];

	phoneItems: ComboBoxItemInterface[] = [{ Id: -1, Name: '' }];
	phone = this.phoneItems[0];

	identifierItems: ComboBoxItemInterface[] = [{ Id: -1, Name: '' }];
	identifier = this.identifierItems[0];

	items: SMSInterface[] = [];
	headers: DataTableHeader[] = [
		{ text: 'Номер телефона', value: 'MobileNumber' },
		{ text: 'Ответ сервера', value: 'Result', sortable: false },
		{ text: 'Оператор', value: 'DbOperId' },
		{ text: 'Время отправки', value: 'Created' },
	];

	sendSMS(): void {
		if (this.phone.Id < 0 || this.smsCategory.Id < 0) return;
		let ident = 0;
		if (this.identifier != null) {
			ident = this.identifier.Id;
		}
		const f: SendSMSInterface = {
			AccountId: this.$pageView.accountId,
			IdentId: ident,
			PhoneId: this.phone.Id,
			Preset: this.smsCategory.Id,
		};

		this.loading = true;
		this.$api.request('Account/SendSMS', f).then((r) => {
			if (r == null) return;
			this.$api.request<SMSInterface[]>('Account/GetSMS', this.$pageView.accountId).then((r) => {
				this.loading = false;
				if (r == null) return;
				this.items = r.data;
			});
			this.$api.alertShow(AlertType.info, 'Сообщени успешно отправлено, скорее всего');
			this.smsCategory = this.smsCategoryItems[0];
			this.phone = this.phoneItems[0];
		});
	}

	mounted(): void {
		this.$pageView.accountId = Number(this.$route.params.id);

		this.$api.request<ComboBoxItemInterface[]>('Account/GetPhones', this.$pageView.accountId).then((r) => {
			this.loading = false;
			if (r == null) return;
			if (r.data.length > 0) {
				this.phoneItems = r.data;
				this.phone = r.data[0];
			}
		});

		this.$api.request<SMSInterface[]>('Account/GetSMS', this.$pageView.accountId).then((r) => {
			if (r == null) return;
			this.items = r.data;
		});

		this.$api.request<ComboBoxItemInterface[]>('Account/GetIdentifiers', this.$pageView.accountId).then((r) => {
			if (r == null) return;
			if (r.data.length > 0) {
				this.identifierItems = r.data;
				this.identifier = this.identifierItems[0];
			}
		});
	}
}
