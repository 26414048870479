







import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class UserName extends Vue {
	@Prop()
	name!: string;

	@Prop()
	type!: number;

	@Prop()
	chatId!: number;
}
