







































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { HelpDeskDocumentInterface, HelpDeskImageInterface, HelpDeskTicketMessageInterface, HelpDeskTicketMessageType } from '@/types';
import HelpDeskMessageBlock from '@/Components/HelpDeskMessageBlock.vue';
import pako from 'pako';

@Component({ components: { HelpDeskMessageBlock } })
export default class HelpDeskMessage extends Vue {
	@Prop()
	item!: HelpDeskTicketMessageInterface;

	messageText = '';
	image = {} as HelpDeskImageInterface;
	document = {} as HelpDeskDocumentInterface;

	download(doc: HelpDeskDocumentInterface): void {
		this.$api.file(doc.FileName).then(async (r) => {
			if (r == null) return;

			const b8a = new Uint8Array(await new Response(r.data).arrayBuffer());
			const data = pako.inflate(b8a);
			const a = document.createElement('a');
			a.href = window.URL.createObjectURL(new Blob([data], { type: doc.MimeType }));
			a.download = doc.OriginalFileName;
			a.click();
		});
	}

	decompress(text: string): string {
		const b8a = atob(text)
			.split('')
			.map((x) => x.charCodeAt(0));

		return pako.inflate(new Uint8Array(b8a), { to: 'string' });
	}

	@Watch('item.Message')
	updateMessage(): void {
		switch (this.item.Type) {
			case HelpDeskTicketMessageType.Text:
			case HelpDeskTicketMessageType.Error:
				this.messageText = this.decompress(this.item.Message);
				break;
			case HelpDeskTicketMessageType.Preset:
				this.messageText = JSON.parse(this.decompress(this.item.Message)).Name;
				break;
			case HelpDeskTicketMessageType.Sticker:
				this.image = JSON.parse(this.decompress(this.item.Message));
				break;
			case HelpDeskTicketMessageType.Photo:
				this.image = JSON.parse(this.decompress(this.item.Message));
				break;
			case HelpDeskTicketMessageType.Document:
				this.document = JSON.parse(this.decompress(this.item.Message));
				break;
		}
	}

	mounted(): void {
		this.updateMessage();
	}
}
