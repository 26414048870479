import Vue from 'vue';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { AlertType, DbOperInterface } from '@/types';

export interface RootStateInterface {
	isLoggedIn: boolean;
	navigationVisible: boolean;
	alert: {
		visible: boolean;
		message: string;
		type: AlertType;
	};
	dbOpers: { [Id: number]: DbOperInterface };
	currentOperId: number;
}

class API {
	private rootState = Vue.observable<RootStateInterface>({
		isLoggedIn: false,
		navigationVisible: true,
		alert: {
			visible: false,
			message: '',
			type: AlertType.error,
		},
		dbOpers: {},
		currentOperId: -1,
	});

	public get isLoggedIn() {
		return this.rootState.isLoggedIn;
	}
	public set isLoggedIn(v: boolean) {
		this.rootState.isLoggedIn = v;
	}

	public get navigationVisible() {
		return this.rootState.navigationVisible;
	}
	public set navigationVisible(v: boolean) {
		this.rootState.navigationVisible = v;
	}

	public get alert() {
		return this.rootState.alert;
	}

	public get dbOpers() {
		return this.rootState.dbOpers;
	}

	public get currentOperId() {
		return this.rootState.currentOperId;
	}

	public set currentOperId(v: number) {
		this.rootState.currentOperId = v;
	}

	constructor() {
		axios.interceptors.response.use(
			(config) => {
				const token = localStorage.getItem('token');
				config.headers['Authorization'] = token ? `bearer ${token}` : '';
				return config;
			},
			(err: AxiosError) => {
				if (err.response) {
					if (err.response.status == 401 && this.isLoggedIn) {
						this.removeToken();
					} else if (err.response.status == 400) {
						this.alertShow(AlertType.error, 'Ошибка данных: При обращении к серверу произошла ошибка. Проверьте правильность введенных данных.');
					} else if (err.response.status == 500) {
						this.alertShow(AlertType.error, 'Сервер не доступен, а такого быть не должно!');
					}
				} else {
					this.alertShow(AlertType.error, 'Сервер не доступен, а такого быть не должно!');
				}
			}
		);

		const token = localStorage.getItem('token');

		if (token) {
			this.setToken(token);
		}
	}

	getDbOpers() {
		this.request<DbOperInterface[]>('Account/GetDbOpers').then((r) => {
			if (r == null) return;

			const DbOpers = r.data;

			for (let i = 0; i < DbOpers.length; i++) {
				const o = DbOpers[i];
				this.rootState.dbOpers[o.Id] = o;
			}
		});
	}

	alertShow(type: AlertType, message: string) {
		this.rootState.alert.type = type;
		this.rootState.alert.message = message;
		this.rootState.alert.visible = true;
	}
	alertHide() {
		this.rootState.alert.visible = false;
	}

	removeToken(): void {
		this.request('Account/RemoveToken');
		this.isLoggedIn = false;
		localStorage.removeItem('token');
	}

	setToken(token: string): void {
		this.isLoggedIn = true;
		localStorage.setItem('token', token);
		axios.defaults.headers['Authorization'] = token ? `bearer ${token}` : '';

		this.request<number>('Account/VerifyToken').then((r) => {
			if (r == null) return;
			this.currentOperId = r.data;
		});
		this.getDbOpers();
	}

	request<T>(path: string): Promise<AxiosResponse<T>>;
	request<T>(path: string, form: unknown): Promise<AxiosResponse<T>>;
	request<T>(path: string, param?: string | number): Promise<AxiosResponse<T>> {
		if (param) {
			if (typeof param === 'number' || typeof param === 'string') {
				return axios.get(`/API/${path}?q=${param}`);
			} else {
				return axios.post('/API/' + path, param);
			}
		} else {
			return axios.get('/API/' + path);
		}
	}

	file(name: string): Promise<AxiosResponse<Blob>> {
		return axios.get('/files/' + name, {
			responseType: 'blob',
			maxContentLength: 100000000,
			maxBodyLength: 1000000000,
		});
	}

	upload<T>(path: string, file: File): Promise<AxiosResponse<T>> {
		const data = new FormData();
		data.append('File', file);
		return axios.post('/API/' + path, data, { headers: { 'Content-Type': 'multipart/form-data' } });
	}
}

declare module 'vue/types/vue' {
	interface Vue {
		$api: API;
	}
}

Vue.prototype.$api = new API();
