




import { Vue, Component } from 'vue-property-decorator';

interface IApiRequest {
	readonly Procedure: number;
	Param: IApiRequestParam;
}

interface IApiRequestParam {
	id: number;
}

interface ICmd1 extends IApiRequestParam {
	id: number;
	s: string;
}

@Component
export default class Test extends Vue {
	loading = true;

	mounted(): void {
		const f: ICmd1 = { id: 0, s: 'qwe' };

		this.$api
			.request<unknown>('Account/Test', { Procedure: 1, Param: f } as IApiRequest)
			.then((r) => {
				this.loading = false;
				if (r == null) return;

				console.log(r);
			});
		return;
	}
}
