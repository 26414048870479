




















import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Action extends Vue {
	mounted(): void {
		this.$pageView.accountId = Number(this.$route.params.id);
	}
}
