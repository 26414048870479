




import { Vue, Component, Prop } from 'vue-property-decorator';
import { HelpDeskTicketStateTypes } from '@/types';

interface StateDescription {
	name: string;
	title: string;
	color: string;
}

@Component
export default class StateName extends Vue {
	@Prop()
	state!: HelpDeskTicketStateTypes;

	names = {
		[HelpDeskTicketStateTypes.New]: { name: 'новое', title: 'Поступило новое обращение', color: 'red--text text--darken-4' } as StateDescription,
		[HelpDeskTicketStateTypes.Closed]: { name: 'закрыто', title: 'Обращение было закрыто', color: 'teal--text text--darken-4' } as StateDescription,
		[HelpDeskTicketStateTypes.InWork]: { name: 'в работе', title: 'Оператор дал ответ, но ответ от пользователя еще не получен' } as StateDescription,
		[HelpDeskTicketStateTypes.AwaitAnswer]: { name: 'ждет ответа', title: 'Пользователь ждет ответа оператора', color: 'red--text text--darken-4' } as StateDescription,
		[HelpDeskTicketStateTypes.Suspended]: { name: 'приостановлено', title: 'Работа над обращением приостановлена' } as StateDescription,
		[HelpDeskTicketStateTypes.Error]: { name: 'ошибка', title: 'Последняя операция завершилось ошибкой', color: 'red darken-4 white--text px-2' } as StateDescription,
	};

	mounted(): void {
		return;
	}
}
