import Vue from 'vue';
import App from './App.vue';
import router from './Modules/router';
import vuetify from './Modules/vuetify';
import './Modules/colors';
import './Modules/api';
import { PageViewInterface } from './types';

declare module 'vue/types/vue' {
	interface Vue {
		$pageView: PageViewInterface;
	}
}
Vue.config.productionTip = false;

const requireComponent = require.context('@/Components/', false, /_[\w-]+\.vue$/);

requireComponent.keys().forEach((fileName) => {
	const componentConfig = requireComponent(fileName);
	const componentName = fileName
		.replace(/^\.\/_/, '')
		.replace(/\.\w+$/, '')
		.split('-')
		.map((kebab) => kebab.charAt(0).toUpperCase() + kebab.slice(1))
		.join('');

	Vue.component(componentName, componentConfig.default || componentConfig);
});

new Vue({
	router,
	vuetify,
	render: (h) => h(App),
}).$mount('#app');
