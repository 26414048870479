


















import { Vue, Component } from 'vue-property-decorator';
import { AlertType } from '@/types';

interface Account {
	fName: string;
	mName: string;
	lName: string;
	eMail: string;
	login: string;
	password: string;
}

@Component
export default class CreateService extends Vue {
	loading = false;

	account: Account = {
		fName: '',
		mName: '',
		lName: '',
		eMail: '',
		login: '',
		password: '',
	};

	createAccount(): void {
		this.loading = true;
		this.$api.request<boolean>('Account/CreateAccount', this.account).then((r) => {
			this.loading = false;
			if (r == null) return;
			this.$api.alertShow(AlertType.info, 'Лицевой счет успешно создан. Номер лицевого счета ' + r.data);
		});
	}
}
