import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import colors from 'vuetify/lib/util/colors';
import ru from 'vuetify/src/locale/ru';

Vue.use(Vuetify);

const opts = {
	breakpoint: {
		scrollBarWidth: 16,
		thresholds: {
			xs: 600,
			sm: 960,
			md: 1280,
		},
	},
	lang: {
		locales: { ru },
		current: 'ru',
	},
	theme: {
		themes: {
			dark: {
				primary: colors.teal.darken4,
				secondary: colors.teal.base,
				accent: colors.teal.base,
				error: '#FF5252',
				info: '#2196F3',
				success: '#4CAF50',
				warning: '#FB8C00',
			},
		},
	},
};
const vuetify = new Vuetify(opts);
export default vuetify;
