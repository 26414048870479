













import { Vue, Component } from 'vue-property-decorator';

interface Domofon {
	Id: number;
	Name: string;
	Stream: string;
	SipLogin: string;
	ApiUrl: string;
}

@Component
export default class DirectoryDomofons extends Vue {
	loading = false;
	items: Domofon[] = [];
	headers = [
		{ text: 'Название домофона', value: 'Name' },
		{ text: 'Стрим', value: 'Stream' },
		{ text: 'SIP-логин', value: 'SipLogin' },
		{ text: 'API', value: 'ApiUrl' },
	];

	mounted(): void {
		this.loading = true;
		this.$api.request<Domofon[]>('Directory/GetDomofons').then((r) => {
			this.loading = false;
			if (r == null) return;
			this.items = r.data;
		});
	}
}
